<template>
  <div class="GalleryUploadOpt">
    <div class="GalleryUploadOptions">
      <label class="addFilesButton primary-circle button" :for="`in${parentId}`">
        <iconic name="plus" />
      </label>
    </div>
    <Spacer num="1" v-if="$isDesktop" />
  </div>
</template>

<script>
export default {
  props: ["parentId"],
};
</script>

<style lang="scss">
.GalleryUploadOptions {
  border-radius: $mpadding;
  display: flex;
  justify-content: center;
  align-items: center;

  .button {
    @include Glass(#fff, 0.7);
  }

  @include screen($tablet_width) {
    padding: $mpadding;
  }
}
</style>
